import React from 'react'

import Layout from '../Components/Layout'
import SEO from '../Components/seo'

const trevinImage = require('../images/trevin.jpg')

const IndexPage = () => (
    <Layout>
        <SEO title="Home" />

        <span
            className="block mx-auto 
        text-gold font-bold text-lg
        max-w-lg text-center mt-4"
        >
            ABOUT
        </span>
        <h1
            className="text-5xl text-center font-serif
            mx-auto mb-12 max-w-lg
            border-b-2 border-gold"
        >
            TREVIN H. PREBLE
        </h1>
        <div
            className="container mx-auto mb-16 
            flex flex-col sm:flex-row 
            align-start justify-start"
        >
            <div className="w-full sm:w-auto mr-4">
                <img
                    src={trevinImage}
                    className="w-full rounded mx-auto mb-8"
                    style={{
                        maxWidth: '200px',
                        maxHeight: '400px',
                    }}
                />
            </div>
            <div className="w-full sm:w-2/3 sm:ml-8 pb-8 px-4">
                <p className="text-lg text-justify">
                    Trevin Preble practices in all areas of family law,
                    including divorce and child custody; criminal law including
                    DUI, misdemeanor and felony; Chapter 7 bankruptcy; as well
                    as civil litigation and general practice matters. He has
                    handled cases throughout southeast and central Nebraska
                    including Hall County and Lancaster County.
                    <br />
                    <br />
                    Trevin received his Bachelor’s in Business Management from
                    Doane College in 2002, and graduated from the University of
                    Nebraska College of Law in 2013. Prior to his law career,
                    Trevin worked for two major insurance companies in Lincoln.
                    <br />
                    <br />
                    Trevin has lived in Nebraska his entire life, from one end
                    (Chadron) to the other (Lincoln). He is married and the
                    father of 4 boys.
                </p>
            </div>
        </div>
    </Layout>
)

export default IndexPage
